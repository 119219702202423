import React from "react";

const ProcessAreaOne = () => {
  return (
    <section className="process-area-1 space position-relative">
      <div className="portfolio-shape-img shape-mockup d-lg-block d-none">
        <img
          className="about1-shape-img-1 spin"
          src="/assets/img/normal/about_shape1-2.svg"
          alt="Fixturbo"
        />
        <img
          className="about1-shape-img-2 spin2"
          src="/assets/img/normal/about_shape1-1.svg"
          alt="Fixturbo"
        />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title">Serwis i naprawy</span>
              <h2 className="sec-title">
                Najlepsze rozwiązania w zakresie napraw samochdów
              </h2>
            </div>
          </div>
        </div>
        <div className="row gy-30">
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-1.svg" alt="Fixturbo" />
              </div>
              <h4 className="process-card-title">Serwis Klimatyzacji</h4>
              <p className="process-card-text">
              Serwis klimatyzacji w samochodach osobowych jest ważnym elementem
              utrzymania pojazdu w dobrym stanie technicznym oraz zapewnienia
              komfortu użytkowania. Regularne przeglądy i konserwacja systemu klimatyzacji
              pomagają w jego sprawnym działaniu oraz przedłużają żywotność podzespołów.
              </p>
            </div>
          </div>
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card process-card-center">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-2.svg" alt="Fixturbo" />
              </div>
              <h4 className="process-card-title">Wymiana rozrządu</h4>
              <p className="process-card-text">
              Wymiana paska rozrządu jest jedną z kluczowych czynności serwisowych w samochodach z silnikami spalinowymi.
              Pasek rozrządu odgrywa fundamentalną rolę w synchronizacji pracy wałka rozrządu i wału korbowego,
              co jest niezbędne do prawidłowego działania silnika. Uszkodzony lub zużyty pasek rozrządu może prowadzić
              do poważnych uszkodzeń silnika, dlatego jego regularna wymiana jest niezwykle ważna
              </p>
            </div>
          </div>
          <div className="col-lg-4 process-card-wrap">
            <div className="process-card">
              <div className="process-card-icon">
                <img src="/assets/img/icon/process-icon-1-3.svg" alt="Fixturbo" />
              </div>
              <h4 className="process-card-title">Naprawy bierzące</h4>
              <p className="process-card-text">
              Naprawy bieżące samochodu są kluczowym elementem utrzymania pojazdu w dobrym stanie technicznym oraz zapewnienia bezpieczeństwa na drodze.
              Regularne monitorowanie i naprawa drobnych usterek mogą zapobiec poważniejszym awariom w przyszłości
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessAreaOne;
