import React, { useEffect, useState } from "react";
import HeaderTwo from "../components/HeaderTwo";

import FooterAreaTwo from "../components/FooterAreaTwo";
import Breadcrumb from "../components/Breadcrumb";
import AboutTwo from "../components/AboutTwo";
import ProcessAreaOne from "../components/ProcessAreaOne";
import CTAAreaOne from "../components/CTAAreaOne";
import TestimonialOne from "../components/TestimonialOne";
import SubscribeOne from "../components/SubscribeOne";
import TeamAreaTwo from "../components/TeamAreaTwo";
import Preloader from "../helper/Preloader";

const AboutPage = () => {
  let [active, setActive] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 2000);
  }, []);
  return (
    <>
      {/* Preloader */}
      {active === true && <Preloader />}

      {/* Header two */}
      <HeaderTwo />

      {/* Breadcrumb */}
      <Breadcrumb title={"O nas"} />

      {/* About Area */}
      <AboutTwo />

      {/* Process Area One */}
      <ProcessAreaOne />

      {/* CTA Area One 
      <CTAAreaOne />*/}

      {/* Testimonial One 
      <TestimonialOne />*/}

      {/* Team Area Two 
      <TeamAreaTwo />*/}

      {/* Subscribe One 
      <SubscribeOne />*/}

      {/* Footer Area One */}
      <FooterAreaTwo />
    </>
  );
};

export default AboutPage;
