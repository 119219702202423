import React from "react";

const WhyChooseUsOne = () => {
  return (
    <div className="wcu-area-1 space-bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title">Usługi</span>
              <h2 className="sec-title">
              Oferujemy naprawy mechaniczne, elektromechaniczne oraz serwis klimatyzacji{" "}
                <img
                  className="title-bg-shape shape-center"
                  src="assets/img/bg/title-bg-shape.png"
                  alt="Fixturbo"
                />
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gy-40 gx-60 align-items-center">
          <div className="col-xl-4 order-xl-2">
            <div className="wcu-thumb-1">
              <img src="assets/img/normal/wcu-thumb-1-1.png" alt="Fixturbo" />
            </div>
          </div>
          <div className="col-xl-4 col-md-6 order-xl-1">
            <div className="wcu-card-wrap right-wrap">
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-1.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Naprawa układu zawieszenia</h4>
                  <p className="wcu-card-text">
                  Naprawa układu zawieszenia samochodu jest kluczowa dla komfortu jazdy oraz bezpieczeństwa na drodze.
                   Układ zawieszenia odpowiada za amortyzację drgań oraz utrzymanie kontaktu kół z nawierzchnią{" "}
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-2.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Wymiana sprzegła </h4>
                  <p className="wcu-card-text">
                  Wymiana sprzęgła w samochodzie jest skomplikowanym zadaniem,
                  które wymaga odpowiednich narzędzi i umiejętności mechanicznych.
                  Sprzęgło jest kluczowym elementem w układzie napędowym,
                  umożliwiającym zmianę biegów i przekazywanie mocy z silnika do kó{" "}
                  </p>
                </div>
              </div>
              <div className="wcu-card">
                <div className="icon">
                  <img src="assets/img/icon/wcu-icon_2-3.svg" alt="Fixturbo" />
                </div>
                <div className="wcu-card-details">
                  <h4 className="wcu-card-title">Wymina oleju</h4>
                  <p className="wcu-card-text">
                  Regularna wymiana oleju silnikowego i filtra jest kluczowa dla prawidłowego funkcjonowania silnika.
                  Powinna być wykonywana co 10 000-15 000 km lub co rok, zależnie od zaleceń producenta.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 order-xl-3">
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-4.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                <h4 className="wcu-card-title">Serwis Klimatyzacji</h4>
                <p className="wcu-card-text">
                Serwisowanie klimatyzacji samochodowej jest kluczowe dla utrzymania jej wydajności oraz zapewnienia komfortu i zdrowia użytkowników pojazdu.
                 Regularne przeglądy i konserwacja systemu klimatyzacji mogą zapobiec poważnym usterkom oraz poprawić efektywność chłodzenia{" "}
                </p>
              </div>
            </div>
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-5.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                <h4 className="wcu-card-title"> Kontrola i wymiana klocków hamulcowych</h4>
                <p className="wcu-card-text">
                Klocki hamulcowe zużywają się z czasem i muszą być regularnie sprawdzane oraz wymieniane,
                 zazwyczaj co 20 000-50 000 km.{" "}
                </p>
              </div>
            </div>
            <div className="wcu-card">
              <div className="icon">
                <img src="assets/img/icon/wcu-icon_2-6.svg" alt="Fixturbo" />
              </div>
              <div className="wcu-card-details">
                <h4 className="wcu-card-title">Wymiana świec zapłonowych</h4>
                <p className="wcu-card-text">
                Świece zapłonowe odpowiadają za zapłon mieszanki paliwowo-powietrznej. Powinny być wymieniane co 30 000-50 000 km.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUsOne;
