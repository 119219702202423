import React from "react";

const ContactArea = () => {
  return (
    <>
      <div className="contact-area space">
        <div className="container">
          <div className="row gy-4 justify-content-center">
            <div className="col-xxl-3 col-lg-4 col-md-6">
              <div className="contact-info">
                <div className="contact-info_icon">
                  <i className="fas fa-map-marker-alt" />
                </div>
                <h6 className="contact-info_title">Adres</h6>
                <p className="contact-info_text">
                  Ul.Kolejowa 8a
                </p>
                <p className="contact-info_text">57-215 Srebrna Góra</p>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-md-6">
              <div className="contact-info">
                <div className="contact-info_icon">
                  <i className="fas fa-phone-alt" />
                </div>
                <h6 className="contact-info_title">Numer Telefonu</h6>
                <p className="contact-info_text">
                  <a href="tel:514 298 406">514 298 406</a>
                </p>
                <p className="contact-info_text">
                  <a href="tel:514 298 406">.</a>
                </p>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-md-6">
              <div className="contact-info">
                <div className="contact-info_icon">
                  <i className="fas fa-clock" />
                </div>
                <h6 className="contact-info_title">Godziny Otwarcia</h6>
                <p className="contact-info_text">Sobota od10.00 do 17.00</p>
                <p className="contact-info_text">Pozostałe dni 8.00 do 17.00</p>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-4 col-md-6">
              <div className="contact-info">
                <div className="contact-info_icon">
                  <i className="fas fa-envelope" />
                </div>
                <h6 className="contact-info_title">E-mail</h6>
                <p className="contact-info_text">
                  <a href="pawelsg@poczta.fm">
                  pawelsg@poczta.fm
                  </a>
                </p>
                <p className="contact-info_text">
                  <a href="mailto:pawelsg@poczta.fm">.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space-bottom">
        <div className="container">
          <div className="map-sec">
            <iframe
            //src ="https://mapa.targeo.pl/Srebrna%2520G%25C3%25B3ra%2520%2520Kolejowa%25208a,19,16.66517,50.57512"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2533.7964491705225!2d16.66242797610566!3d50.575148378377996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e3957d5751295%3A0x39a5ad5a0f5c184b!2sKolejowa%208A%2C%2057-215%20Srebrna%20G%C3%B3ra!5e0!3m2!1spl!2spl!4v1718281848716!5m2!1spl!2spl"
              allowFullScreen=""
              loading="lazy"
              title="address"
            />
          </div>
        </div>
      </div>
      <div className="space-bottom">
        <div className="container">
          
        </div>
      </div>
    </>
  );
};

export default ContactArea;
