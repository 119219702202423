import React from "react";
import { Link } from "react-router-dom";

const HeroTwo = () => {
  return (
    <div
      className="hero-wrapper hero-2"
      id="hero"
      style={{ backgroundImage: "url(assets/img/hero/servis_bg_2_1.png)" }}
    >
      <div className="hero-shape2-1">
        <span className="hero-shape2-2" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xxl-6 col-xl-5 col-lg-8">
            <div className="hero-style2">
              <span className="sub-title text-white">Autoserwis</span>
              <h1 className="hero-title text-white">
              Serwis{" Wykonujemy naprawy"}
                <span>
                  <img src="assets/img/hero/hero_shape_2.png" alt="autoserwis" />
                  
                </span>{" "}
                 oraz wymiany:
              </h1>
              <p className="hero-text text-white">
              układów napędowych, układów kierowniczych, układów hamulcowych, silników, pasków oraz serwis klimatyzacji, nabijanie, odgrzybiaie, sprawdzanie szczelności układu, konserwacja  
              </p>
              <div className="btn-group">
                <Link to="/about" className="btn">
                  Sprawdź
                </Link>
                <div className="call-media-wrap">
                  <div className="icon">
                    <img src="assets/img/icon/phone-1.svg" alt="autoserwis" />
                  </div>
                  <div className="media-body">
                    <h6 className="title text-white">Zadzwoń:</h6>
                    <h4 className="link">
                      <Link className="text-white" to="tel:514 298 406">
                      514 298 406
                      </Link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroTwo;
