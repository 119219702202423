import React from "react";
import { Link } from "react-router-dom";

const ServiceAreaOneMultiImg = () => {
  return (
    <div className="service-area-1 space overflow-hidden">
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="service-card style-shadow">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img src="assets/img/icon/service-icon_1-1.svg" alt="Autoserwis" />
                </div>
                <h4 className="service-card_title h5">
                  <Link to="/service">Naprawy bierzące  </Link>
                </h4>
                <p className="service-card_text">
                Sprawdzenie stanu i wymiana zużytych klocków oraz tarcz hamulcowych Sprawdzenie poziomu i stanu płynów, takich jak płyn chłodniczy, płyn hamulcowy,
                 płyn do wspomagania kierownicy, płyn do spryskiwaczy.{" "}
                </p>
                <Link to="/service" className="link-btn">
                  Więcej <i className="fas fa-arrow-right" />
                </Link>
              </div>
              <div className="service-card_img">
                <img src="assets/img/service/service-1-1.png" alt="Autoserwis" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style-shadow">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img src="assets/img/icon/service-icon_1-2.svg" alt="Autoserwis" />
                </div>
                <h4 className="service-card_title h5">
                  <Link to="/service">Serwis Klimatyzacji</Link>
                </h4>
                <p className="service-card_text">
                Sprawdzenie i uzupełnienie czynnika chłodniczego.
                Wymiana filtra kabinowego oraz kontrola działania systemu klimatyzacji.{" "}
                </p>
                <Link to="/service" className="link-btn">
                  Więcej <i className="fas fa-arrow-right" />
                </Link>
              </div>
              <div className="service-card_img">
                <img src="assets/img/service/service-1-2.png" alt="Autoserwis" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style-shadow">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img src="assets/img/icon/service-icon_1-3.svg" alt="Autoserwis" />
                </div>
                <h4 className="service-card_title h5">
                  <Link to="/service">Odgrzybianie i ozonowanie</Link>
                </h4>
                <p className="service-card_text">
                Ozonowanie to proces, w którym za pomocą generatora ozonu wytwarza się ozon (O₃), który następnie jest rozprowadzany po wnętrzu samochodu. Ozon ma silne właściwości utleniające, co pozwala na skuteczne usunięcie bakterii,
                wirusów, grzybów, pleśni oraz nieprzyjemnych zapachów.{" "}
                </p>
                <Link to="/service" className="link-btn">
                  Więcej <i className="fas fa-arrow-right" />
                </Link>
              </div>
              <div className="service-card_img">
                <img src="assets/img/service/service-1-3.png" alt="Autoserwis" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style-shadow">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img src="assets/img/icon/service-icon_1-4.svg" alt="Autoserwis" />
                </div>
                <h4 className="service-card_title h5">
                  <Link to="/service">Naprawy układów zawieszenia</Link>
                </h4>
                <p className="service-card_text">
                Sprawdzenie stanu amortyzatorów, sprężyn, łożysk i tulei.
                Wymiana zużytych lub uszkodzonych elementów zawieszenia{" "}
                </p>
                <Link to="/service" className="link-btn">
                  Więcej <i className="fas fa-arrow-right" />
                </Link>
              </div>
              <div className="service-card_img">
                <img src="assets/img/service/service-1-4.png" alt="Autoserwis" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style-shadow">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img src="assets/img/icon/service-icon_1-5.svg" alt="Autoserwis" />
                </div>
                <h4 className="service-card_title h5">
                  <Link to="/service">Kontrola i wymiana pasków napędowych</Link>
                </h4>
                <p className="service-card_text">
                Sprawdzenie stanu paska rozrządu, paska klinowego i innych pasków napędowych.
                Wymiana pasków, jeśli są zużyte lub uszkodzone.{" "}
                </p>
                <Link to="/service" className="link-btn">
                  Więcej <i className="fas fa-arrow-right" />
                </Link>
              </div>
              <div className="service-card_img">
                <img src="assets/img/service/service-1-5.png" alt="Autoserwis" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style-shadow">
              <div className="service-card_content">
                <div className="service-card_icon">
                  <img src="assets/img/icon/service-icon_1-6.svg" alt="Autoserwis" />
                </div>
                <h4 className="service-card_title h5">
                  <Link to="/service">Diagnostyka</Link>
                </h4>
                <p className="service-card_text">
                Podłączenie samochodu do komputera diagnostycznego w celu sprawdzenia błędów i usterek w systemach elektronicznych.
                Naprawa wykrytych problemów.{" "}
                </p>
                <Link to="/service" className="link-btn">
                  Więcej <i className="fas fa-arrow-right" />
                </Link>
              </div>
              <div className="service-card_img">
                <img src="assets/img/service/service-1-6.png" alt="Autoserwis" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAreaOneMultiImg;
